var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"settings"}},[_c('v-app-bar',{staticClass:"px-1 mt-2",attrs:{"flat":"","color":"transparent","height":"64","min-height":"64"}},[_c('v-breadcrumbs',{staticClass:"pl-2 font-weight-medium body-1",attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('v-spacer')],1),_c('v-container',{staticClass:"pt-0 px-5",attrs:{"fluid":""}},[_c('v-alert',{attrs:{"color":"primary","text":""}},[_vm._v("Configuracion del sistema, no necesitas cambiar estos ajustes.")]),_c('v-card',{attrs:{"flat":""}},[_c('v-app-bar',{attrs:{"flat":"","color":"white"}},[_c('span',[_vm._v("Tokens")]),_c('v-spacer'),_c('v-btn',{staticClass:"text-none",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/configuracion/token/nuevo')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Nuevo ")],1)],1),_c('v-data-table',{attrs:{"items":_vm.tokens,"headers":_vm.headers,"height":_vm.height,"header-props":{
            'sort-by-text': 'Ordenar por'
        },"no-results-text":"No se encontraron resultados","no-data-text":"No hay información para mostrar","footer-props":{
        showFirstLastPage: true,
        'items-per-page-text':'Filas por página',
        'pageText': '{0}-{1} de {2}',
        'items-per-page-all-text': 'Todas',
        'items-per-page-options': [
            20, 30, 45, -1
        ]
        }},on:{"click:row":_vm.handleToken}})],1)],1),_c('transition',{attrs:{"name":"slide-fade"}},[_c('router-view',{key:_vm.$route.path,attrs:{"name":"settings"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }