<template>
    <div id="settings">
        <v-app-bar flat color="transparent" class="px-1 mt-2" height="64" min-height="64">
        <v-breadcrumbs :items="items" class="pl-2 font-weight-medium body-1">
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
    </v-app-bar>
    <v-container fluid class="pt-0 px-5">
        <v-alert color="primary" text>Configuracion del sistema, no necesitas cambiar estos ajustes.</v-alert>
        <v-card flat>
            <v-app-bar flat color="white">
                <span>Tokens</span>
                <v-spacer></v-spacer>
                <v-btn depressed class="text-none" color="primary" @click="$router.push('/configuracion/token/nuevo')">
                    <v-icon left>mdi-plus</v-icon>
                    Nuevo
                </v-btn>
            </v-app-bar>
            <v-data-table @click:row="handleToken" :items="tokens" :headers="headers" :height="height" :header-props="{
                'sort-by-text': 'Ordenar por'
            }" no-results-text="No se encontraron resultados" no-data-text="No hay información para mostrar"
            :footer-props="{
            showFirstLastPage: true,
            'items-per-page-text':'Filas por página',
            'pageText': '{0}-{1} de {2}',
            'items-per-page-all-text': 'Todas',
            'items-per-page-options': [
                20, 30, 45, -1
            ]
            }">

        </v-data-table>
        </v-card>   
    </v-container>
    <transition name="slide-fade">
            <router-view name="settings" :key="$route.path"/>
    </transition>
    </div>
</template>
<script>
export default {
    name: 'Configuracion',
    data: () => ({
        height: 350,
        headers: [
            { text: 'Nombre', value: 'name' },
            { text: 'Valor', value: 'value' }
        ],
        items: [
            {
                text: 'Inicio',
                disabled: false,
                href: '/',
            },
            {
                text: 'Configuración',
                disabled: true,
                href: '/configuracion',
            },
        ],
    }),
    methods: {
        handleToken(e){
            this.$router.push({ name: 'edit-token', params: { id: e._id } });
        }
    },
    computed: {
        tokens(){
            return this.$store.getters['token/GET_TOKENS'];
        }
    }
}
</script>